// frontend/src/components/CustomerForm.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api, { getCustomerResponses, saveForm } from '../api'; // saveForm'u buraya ekledik
import PersonalInfo from './formSteps/PersonalInfo';
import ProjectDetails from './formSteps/ProjectDetails';
import DesignDetails from './formSteps/DesignDetails';
import Integrations from './formSteps/Integrations';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const CustomerForm = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Step 1 - Personal Info
    name: '',
    email: '',
    phoneNumber: '',
    isPointOfContact: false,
    pocName: '',
    pocEmail: '',
    pocPhone: '',

    // Step 2 - Project Details
    mainMarket: '',
    additionalMarket: '',
    languages: '',
    hasDigitalProducts: false,
    productCount: '',
    categoryCount: '',
    hasLiveSite: false,
    needOrderTransfer: false,
    orderCount: '',

    // Step 3 - Design Details
    generalDesignBrief: '',
    exampleDesignUrls: '',
    headerDesignBrief: '',
    headerDesignScreenshots: [],
    footerDesignBrief: '',
    footerDesignScreenshots: [],
    homepageDesignBrief: '',
    homepageDesignScreenshots: [],
    collectionPageDesignBrief: '',
    collectionPageScreenshots: [],
    productPageDesignBrief: '',
    productPageScreenshots: [],
    otherPagesDesignBrief: '',
    otherPagesScreenshots: [],

    // Step 4 - Integration Information
    paymentInfo: '',
    shippingInfo: '',
    invoicingInfo: '',
    marketplaceIntegrations: '',
    erpIntegrations: '',
    selectedPlugins: []
  });

  const [formCompleted, setFormCompleted] = useState(false);

  const determineStartStep = (formData) => {
    const stepRequirements = {
      1: ['name', 'email', 'phoneNumber'],
      2: ['mainMarket', 'additionalMarket', 'languages', 'hasDigitalProducts', 'productCount', 'categoryCount', 'hasLiveSite', 'needOrderTransfer', 'orderCount'],
      3: ['generalDesignBrief'],
      4: ['paymentInfo', 'shippingInfo', 'invoicingInfo']
    };

    // En son tamamlanan adımı bul
    let lastCompletedStep = 0;
    for (let step = 1; step <= 4; step++) {
      const requiredFields = stepRequirements[step];
      const isStepComplete = requiredFields.every(field => formData[field] && formData[field].toString().trim() !== '');
      
      if (isStepComplete) {
        lastCompletedStep = step;
      } else {
        break; // İlk eksik adımda dur
      }
    }

    return lastCompletedStep + 1;
  };

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        if (user?.customerId) {
          const response = await getCustomerResponses(user.customerId);
          
          if (response?.formStatus === 'completed') {
            navigate(`/form-summary/${user.customerId}`);
            return;
          }

          if (response && response.formData) {
            setFormData(prevData => ({
              ...prevData,
              ...response.formData
            }));
            const startStep = determineStartStep(response.formData);
            setCurrentStep(startStep);
          }
        }
      } catch (error) {
        console.error('Form verileri alınamadı:', error);
      }
    };

    fetchFormData();
  }, [user, navigate]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Input değiştiğinde highlight'ı kaldır
    const element = document.getElementsByName(name)[0];
    if (element) {
      element.classList.remove('highlight');
      element.classList.remove('highlight-missing');
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    
    // Checkbox değiştiğinde highlight'ı kaldır
    const element = document.getElementsByName(name)[0];
    if (element) {
      element.classList.remove('highlight');
      element.classList.remove('highlight-missing');
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: checked
    }));
  };

  const handleSelectChange = (name, value) => {
    // Select değiştiğinde highlight'ı kaldır
    const element = document.getElementsByName(name)[0];
    if (element) {
      element.classList.remove('highlight');
      element.classList.remove('highlight-missing');
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const setFieldValue = (fieldName, value) => {
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  const getRequiredFieldsForCurrentStep = (step) => {
    switch (step) {
      case 1:
        return ['name', 'email', 'phoneNumber', 'pocName', 'pocEmail', 'pocPhone'];
      case 2:
        return ['mainMarket', 'additionalMarket', 'languages', 'hasDigitalProducts', 'productCount', 'categoryCount', 'hasLiveSite', 'needOrderTransfer', 'orderCount'];
      case 3:
        return ['generalDesignBrief', 'exampleDesignUrls', 'headerDesignBrief', 'footerDesignBrief', 'homepageDesignBrief', 'collectionPageDesignBrief', 'productPageDesignBrief'];
      case 4:
        return ['paymentInfo', 'shippingInfo', 'invoicingInfo', 'marketplaceIntegrations', 'erpIntegrations'];
      default:
        return [];
    }
  };

  const validateStep = (step) => {
    const requiredFields = {
      1: [
        { name: 'name', label: 'Adınız' },
        { name: 'email', label: 'E-posta Adresiniz' },
        { name: 'phoneNumber', label: 'Telefon Numaranız' }
      ],
      2: [
        { name: 'mainMarket', label: 'Ana Pazar' },
        { name: 'languages', label: 'Diller' }
      ],
      3: [
        { name: 'generalDesignBrief', label: 'Genel Tasarım Özeti' },
        { name: 'exampleDesignUrls', label: 'Örnek Tasarım URL\'leri' }
      ],
      4: [
        { name: 'paymentInfo', label: 'Ödeme Entegrasyonu' },
        { name: 'shippingInfo', label: 'Kargo Entegrasyonu' },
        { name: 'invoicingInfo', label: 'Fatura Entegrasyonu' },
        { name: 'marketplaceIntegrations', label: 'Pazar Yeri Entegrasyonları' },
        { name: 'erpIntegrations', label: 'ERP Entegrasyonları' }
      ]
    };

    const missingFields = requiredFields[step].filter(field => !formData[field.name]);

    if (missingFields.length > 0) {
      const missingLabels = missingFields.map(field => field.label);
      highlightMissingFields(missingFields.map(field => field.name));
      toast.error(`Lütfen şu alanları doldurun: ${missingLabels.join(', ')}`);
      return false;
    }

    removeHighlight();
    return true;
  };

  const highlightMissingFields = (fields) => {
    fields.forEach(field => {
      const element = document.getElementById(field);
      if (element) {
        element.classList.add('highlight-missing');
      }
    });
  };

  const removeHighlight = () => {
    const highlightedElements = document.querySelectorAll('.highlight-missing');
    highlightedElements.forEach(element => {
      element.classList.remove('highlight-missing');
    });
  };

  const handleNext = async () => {
    if (currentStep < 4) {
      if (validateStep(currentStep)) {
        try {
          await handleSave();
          setCurrentStep(prevStep => prevStep + 1);
          removeHighlight();
        } catch (error) {
          toast.error('İleri gidilemedi: ' + error.message);
        }
      }
    }
  };

  const handleSave = async () => {
    try {
      if (!user || !user.customerId) {
        throw new Error('Kullanıcı bilgileri eksik');
      }

      await saveForm(user.customerId, currentStep, formData);
      toast.success('Form başarıyla kaydedildi');
      
      const updatedResponse = await getCustomerResponses(user.customerId);
      if (updatedResponse && updatedResponse.formData) {
        setFormData(prevData => ({
          ...prevData,
          ...updatedResponse.formData
        }));
      }

    } catch (error) {
      toast.error('Form kaydedilemedi: ' + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = getRequiredFieldsForCurrentStep(currentStep);
    const missingFields = requiredFields.filter(field => !formData[field]);

    if (missingFields.length > 0) {
      highlightMissingFields(missingFields);
      toast.error('Lütfen gerekli alanları doldurun');
      return;
    }

    try {
      await api.post('/customer/complete-form', { customerId: user.id, formData });
      setFormCompleted(true);
      navigate('/form-summary');
      toast.success('Form başarıyla gönderildi!');
    } catch (error) {
      console.error('Form gönderilirken hata oluştu:', error);
      toast.error('Form gönderilemedi!');
    }
  };

  const renderStep = () => {
    switch(currentStep) {
      case 1:
        return <PersonalInfo handleInputChange={handleInputChange} handleCheckboxChange={handleCheckboxChange} values={formData} />;
      case 2:
        return <ProjectDetails 
          handleInputChange={handleInputChange} 
          handleSelectChange={handleSelectChange} 
          values={formData} 
          setFieldValue={setFieldValue}
          setFormData={setFormData}  // Bu satırı ekleyin
          customerId={user.customerId} 
        />;
      case 3:
        return <DesignDetails 
          handleInputChange={handleInputChange} 
          handleSelectChange={handleSelectChange} 
          values={formData} 
          setFieldValue={setFieldValue}
          setFormData={setFormData}  // Bu satırı ekleyin
          customerId={user.customerId} 
        />;
      case 4:
        return <Integrations handleInputChange={handleInputChange} values={formData} />;
      default:
        return null;
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleComplete = async () => {
    try {
      const isConfirmed = window.confirm(
        "Form tamamlandıktan sonra değişiklik yapılamayacaktır. Formu tamamlamak istediğinizden emin misiniz?"
      );

      if (!isConfirmed) {
        return;
      }

      // Önce son adımın verilerini kaydet
      await saveForm(user.customerId, currentStep, formData);

      // Sonra formu tamamla
      const response = await api.post(`/customer/complete-form/${user.customerId}`, {
        formData
      });

      if (response.data) {
        toast.success('Form başarıyla tamamlandı');
        navigate(`/form-summary/${user.customerId}`);
      }
    } catch (error) {
      console.error('Form tamamlama hatası:', error);
      toast.error('Form tamamlanamadı');
    }
  };

  const formik = useFormik({
    initialValues: {
      // ... diğer initial values ...
      phoneNumber: ''
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required('Telefon numarası zorunludur')
        .matches(/^[0-9]{10}$/, 'Geçerli bir telefon numarası giriniz (5XX XXX XX XX)')
    }),
    onSubmit: handleSubmit
  });

  if (formCompleted) {
    navigate('/form-summary');
    return null;
  }

  return (
    <div className="page-container">
      <div className="header-wrapper">
        <div className="container">
          <div className="header">
            <div className="header-logo">
              <img src="/assets/byte-logo.svg" alt="Byte Logo" />
            </div>
            <button onClick={handleLogout} className="header-button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
              Çıkış Yap
            </button>
          </div>
        </div>
      </div>
      <div className="customer-form-container">
        <form onSubmit={formik.handleSubmit} className="customer-form">
          <div className="form-content">
            {renderStep()}
          </div>
        </form>
      </div>
      <div className="navigation-wrapper">
        <div className="container">
          <div className="customer-form-navigation">
            {currentStep > 1 && (
              <button className="backbutton" onClick={() => setCurrentStep(prevStep => prevStep - 1)}>Geri</button>
            )}
            <button className="savebutton" onClick={handleSave}>Kaydet</button>
            {currentStep < 4 && (
              <button className="nextbutton" onClick={handleNext}>İleri</button>
            )}
            {currentStep === 4 && (
              <button className="completebutton" onClick={handleComplete}>Formu Tamamla</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerForm;
