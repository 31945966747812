import React, { useState } from 'react';

const plugins = [
  {
    name: 'Wishlist',
    description: 'Müşterilerin ürünleri favori listelerine kaydetmelerine ve bu liste üzerinden satın almalarına olanak tanır.',
    price: 'Ortalama $5-10 / Ay'
  },
  {
    name: 'Breadcrumbs',
    description: "Ürün ve kategori hiyerarşilerini yönetmenizi sağlar. Shopify'da sunulan varsayılan Breadcrumbs yerine, SEO'ya daha uygun özel bir hiyerarşi kullanmanıza imkan tanır.",
    price: 'Ortalama $5-10 / Ay'
  },
  {
    name: 'Ürün Yorumları',
    description: 'Müşterilerin ürünlere yorum yapmalarını sağlar. Yapılan yorumlar ürün sayfasında, ürün kartlarında yıldız olarak ve ana sayfada görüntülenebilir.',
    price: 'Ortalama $0-10 / Ay'
  },
  {
    name: 'Toplu Ürün Düzenleme',
    description: "Shopify'ın varsayılan ürün yükleme şablonundan bağımsız olarak, yalnızca ihtiyacınız olan alanlarla daha sade bir dosya indirmenize ve kendi Excel şablonunuzu yüklemenize imkan veren eklentilerdir.",
    price: 'Ortalama $20-30 / Ay'
  },
  {
    name: 'XML Entegrasyonu',
    description: 'XML ile düzenli olarak sitenize ürün eklemek ve stokları güncellemek için kullanabileceğiniz eklentilerdir.',
    price: 'Ortalama $20 / Ay'
  },
  {
    name: 'Özel XML Çıktısı',
    description: 'Farklı satış kanalları ve Google, Facebook gibi pazarlama mecraları için özel yapılandırılmış, otomatik olarak güncellenen XML dosyaları hazırlamanıza olanak tanır.',
    price: 'Ortalama $10-20 / Ay'
  },
  {
    name: 'Omnisend',
    description: "Shopify'ın standart e-posta sisteminden daha gelişmiş e-posta pazarlama ihtiyaçlarınız için kullanılabilir.",
    price: 'Müşteri Kitlesenin Büyüklüğüne Göre Değişir'
  },
  {
    name: 'Instagram Feed',
    description: 'Mağazanızda Instagram hesabınızın gönderilerini sergilemenizi sağlayan bir eklentidir. Otomatik güncellenen akış sayesinde mağazanızın dinamik ve güncel görünmesini sağlar.',
    price: 'Ortalama $0-20 / Ay'
  },
  {
    name: 'Mobil Uygulama Eklentisi',
    description: 'Shopify mağazanız için (iOS ve Android) native bir mobil uygulama oluşturmanıza ve yönetmenize imkan tanır. Kullanıcı deneyimini geliştirerek müşteri sadakatini artırır ve mobil satışları destekler.',
    price: 'Ortalama $149-599 / Ay'
  },
  {
    name: 'Çeviri Uygulaması',
    description: 'Mağazanızın çok dilli desteğini artırarak farklı dillerde satış yapmanızı kolaylaştıran bir eklentidir. Ürün açıklamalarını, sayfa içeriklerini ve müşteri bildirimlerini otomatik veya manuel olarak çevirebilirsiniz.',
    price: 'Ortalama $0-20 / Ay'
  },
  {
    name: 'KVKK ve Sözleşme Yönetimi',
    description: 'Mağazanızın KVKK ve diğer yasal gerekliliklere uyumunu güçlendirir. Sözleşme onaylarını ve pazarlama izinlerini loglayarak takip etmenize olanak tanır. Ayrıca, kullanıcı sözleşmelerini sipariş sonrası dinamik veriyle müşterilere otomatik e-posta olarak iletebilirsiniz.',
    price: 'Ortalama $5-20 / Ay'
  },
  {
    name: 'Hreflang SEO App',
    description: 'Klon sitesi bulunan mağazalar için hreflang etiketlerini otomatik olarak yöneterek uluslararası SEO uyumluluğunu artıran bir eklentidir. Google ve diğer arama motorlarında doğru dil ve bölge hedeflemeleriyle sıralamanızı iyileştirir.',
    price: 'Ortalama $5-20 / Ay'
  },
  {
    name: 'AI Meta App',
    description: 'Ürünleriniz ve kategorileriniz için yapay zeka destekli meta açıklamaları, başlıklar ve SEO metinleri oluşturarak SEO performansınızı artıran bir uygulamadır. Daha iyi sıralamalar ve daha yüksek tıklama oranları elde etmenize yardımcı olur.',
    price: 'Ortalama $5-20 / Ay'
  }
];

const recommendedPlugins = ['Wishlist', 'Breadcrumbs', 'Omnisend'];

const Integrations = ({ handleInputChange, values }) => {
  const [selectedApps, setSelectedApps] = useState(values.selectedIntegrations || []);

  const handleAppSelection = (app) => {
    const appWithPrice = `${app.name} (${app.price})`;
    const isSelected = selectedApps.some(selectedApp => 
      selectedApp.includes(app.name)
    );
    
    let updatedApps;
    if (isSelected) {
      updatedApps = selectedApps.filter(selectedApp => 
        !selectedApp.includes(app.name)
      );
    } else {
      updatedApps = [...selectedApps, appWithPrice];
    }
    
    setSelectedApps(updatedApps);
    
    handleInputChange({
      target: {
        name: 'selectedIntegrations',
        value: updatedApps
      }
    });
  };

  const isAppSelected = (appName) => {
    return selectedApps.some(app => app.includes(appName));
  };

  return (
    <div className="integrations-container">
      <h2 className="integrations-title">Entegrasyonlar</h2>
      
      <div className="integrations-section">
        <label className="integrations-label" htmlFor="marketplaceIntegrations">
          Pazar Yeri Entegrasyonları:
        </label>
        <span className="design-details-info">Varsa talep edilen pazaryeri entegrasyonlarınızı belirtiniz. Kullanmak istediğiniz entegratör var ise veya entegratör kullanmak istemiyorsanız ayrıca belirtiniz.</span>
        <textarea
          className="integrations-textarea"
          id="marketplaceIntegrations"
          name="marketplaceIntegrations"
          value={values.marketplaceIntegrations}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-section">
        <label className="integrations-label" htmlFor="erpIntegrations">
          ERP Entegrasyonları:
        </label>
        <span className="design-details-info">ERP entegrasyonu talep ediyorsanız entegratörünüzü belirtiniz. Kullanmak istediğiniz entegratör var ise veya entegratör kullanmak istemiyorsanız ayrıca belirtiniz.</span>
        <textarea
          className="integrations-textarea"
          id="erpIntegrations"
          name="erpIntegrations"
          value={values.erpIntegrations}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-section">
        <label className="integrations-label" htmlFor="paymentInfo">
          Ödeme Entegrasyonu:
        </label>
        <span className="design-details-info">Hangi ödeme yöntemlerini kullanmak istediğinizi belirtiniz. Klon site talep ediliyorsa her site için ayrı ayrı belirtiniz. Havale gibi manuel ödeme yöntemleri için ayrıca hesap bilgilerinizi belirtiniz.</span>
        <span className="design-details-info">Türkiye için en çok kullanılan ödeme methodları: Craftgate, iyzico, Paytr, Hepsipay (Craftgate)</span>
        <textarea
          className="integrations-textarea"
          id="paymentInfo"
          name="paymentInfo"
          value={values.paymentInfo}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-section">
        <label className="integrations-label" htmlFor="shippingInfo">
          Kargo Entegrasyonu:
        </label>
        <span className="design-details-info">Varsa talep edilen kargo entegrasyonlarınızı belirtiniz. Kullanmak istediğiniz entegratör var ise veya entegratör kullanmak istemiyorsanız ayrıca belirtiniz.</span>
        <span className="design-details-info">Türkiye için en çok kullanılan kargo methodları: Yurtiçi Kargo, Aras Kargo, MNG Kargo, Kapıda Ödeme</span>
        <textarea
          className="integrations-textarea"
          id="shippingInfo"
          name="shippingInfo"
          value={values.shippingInfo}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-section">
        <label className="integrations-label" htmlFor="invoicingInfo">
          Fatura Entegrasyonu:
        </label>
        <span className="design-details-info">Varsa talep edilen fatura entegrasyonlarınızı belirtiniz. Kullanmak istediğiniz entegratör var ise veya entegratör kullanmak istemiyorsanız ayrıca belirtiniz.</span>
        <span className="design-details-info">Türkiye için en çok kullanılan faturalandırma firmaları: Paraşüt, BirFatura, Logo, Mikro</span>
        <textarea
          className="integrations-textarea"
          id="invoicingInfo"
          name="invoicingInfo"
          value={values.invoicingInfo}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-plugins">
        <h3 className="integrations-subtitle integrations-label">Kullanmak İstediğiniz Eklentiler:</h3>
        <span className="design-details-info">Eklentileri seçerek kullanmak istediğiniz eklentileri belirtiniz. Seçilen eklentilerin kurulumu Byte tarafından teklif içerisnde yapılacaktır ancak uygulamaların ücretleri her ay Shopify tarafından faturanıza yansıtılacaktır.</span>
        <div className="integrations-plugins-grid">
          {plugins.map((plugin) => (
            <div 
              key={plugin.name} 
              className={`integrations-plugin-item ${isAppSelected(plugin.name) ? 'selected' : ''}`}
              onClick={() => handleAppSelection(plugin)}
            >
              <div className="integrations-plugin-checkbox">
                <input
                  type="checkbox"
                  checked={isAppSelected(plugin.name)}
                  onChange={() => {}} 
                  onClick={(e) => e.stopPropagation()} 
                />
                <label className="integrations-plugin-label">{plugin.name}</label>
                {recommendedPlugins.includes(plugin.name) && (
                  <span className="recommended-badge">Önerilir</span>
                )}
              </div>
              <p className="integrations-plugin-description">{plugin.description}</p>
              <span className="integrations-plugin-price">{plugin.price}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
