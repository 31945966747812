// frontend/src/components/formSteps/DesignDetails.js

import React from 'react';
import Dropzone from '../Dropzone';

const DesignDetails = ({ handleInputChange, values, setFieldValue, customerId }) => {
  return (
    <div className="design-details-container">
      <h2 className="design-details-title">Tasarım Detayları</h2>
      
      <div className="design-details-section">
        <label className="design-details-label" htmlFor="generalDesignBrief">Genel Tasarım Briefi: <span className="formrequiredsign">*</span></label>
        <span className="design-details-info">Sitenin genel tasarımı için briefinizi yazınız. Burada tasarım dilinin nasıl olacağını belirtiniz. Örnek siteler ve bu sitelerdeki tasarımın hangi kısımlarını beğendiğinizi belirtiniz.</span>
        <textarea
          className="design-details-textarea"
          id="generalDesignBrief"
          name="generalDesignBrief"
          value={values.generalDesignBrief}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="design-details-section">
        <label className="design-details-label" htmlFor="exampleDesignUrls">Örnek Tasarım URL'leri: <span className="formrequiredsign">*</span></label>
        <span className="design-details-info">Genel tasarım dili olarak örnek aldığınız site linklerini yazınız.</span>
        <input
          className="design-details-input"
          type="text"
          id="exampleDesignUrls"
          name="exampleDesignUrls"
          value={values.exampleDesignUrls}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="design-details-section">
        <label className="design-details-label" htmlFor="headerDesignBrief">Header Tasarım Briefi:</label>
        <span className="design-details-info">Site header, logo konumu, büyüklüğü, menü konumu ve içeriği için talebinizi mobil ve desktop için ayrı ayrı olacak şekilde açıklayınız.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <textarea
          className="design-details-textarea"
          id="headerDesignBrief"
          name="headerDesignBrief"
          value={values.headerDesignBrief}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="design-details-section">
        <label className="design-details-label">Header Tasarım Ekran Görüntüleri:</label>
        <span className="design-details-info">Beğendiğiniz sitelerin header, menü kullanımlarını mobil ve desktop için ayrı ayrı olacak şekilde ekran görüntüleri olarak ekleyiniz.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <div className="design-details-dropzone">
          <Dropzone 
            fieldName="headerDesignScreenshots" 
            customerId={customerId} 
            setFieldValue={setFieldValue}
            values={values}
          />
        </div>
      </div>

      <div className="design-details-section">
        <label className="design-details-label" htmlFor="footerDesignBrief">Footer Tasarım Briefi:</label>
        <span className="design-details-info">Site footerı için logo, haber bülten formu, ödeme linkleri, sosyal medya ve menüler gibi içerikleri ile birlikte mobil ve desktop için ayrı ayrı olacak şekilde briefinizi yazınız.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <textarea
          className="design-details-textarea"
          id="footerDesignBrief"
          name="footerDesignBrief"
          value={values.footerDesignBrief}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="design-details-section">
        <label className="design-details-label">Footer Tasarım Ekran Görüntüleri:</label>
        <span className="design-details-info">Beğendiğiniz sitelerin footer, logo konumu, haber bülten formu, ödeme linkleri, sosyal medya ve menüler gibi içerikleri mobil ve desktop için ayrı ayrı olacak şekilde ekran görüntüleri olarak ekleyiniz.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <div className="design-details-dropzone">
          <Dropzone 
            fieldName="footerDesignScreenshots" 
            customerId={customerId} 
            setFieldValue={setFieldValue}
            values={values}
          />
        </div>
      </div>

      <div className="design-details-section">
        <label className="design-details-label" htmlFor="homepageDesignBrief">Ana Sayfa Tasarım Briefi:</label>
        <span className="design-details-info">Site ana sayfası için slider, ürün kategorileri, ürünler, banner, içerik bölümü gibi alanların nasıl olacağını mobil ve desktop için ayrı ayrı olacak şekilde belirtebilirsiniz.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <textarea
          className="design-details-textarea"
          id="homepageDesignBrief"
          name="homepageDesignBrief"
          value={values.homepageDesignBrief}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="design-details-section">
        <label className="design-details-label">Ana Sayfa Tasarım Ekran Görüntüleri:</label>
        <span className="design-details-info">Ana sayfa ve içerisinde bulunmasını istediğiniz alanların beğendiğiniz sitelerdeki ekran görüntülerini ekleyiniz.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <div className="design-details-dropzone">
          <Dropzone 
            fieldName="homepageDesignScreenshots" 
            customerId={customerId} 
            setFieldValue={setFieldValue}
            values={values}
          />
        </div>
      </div>

      <div className="design-details-section">
        <label className="design-details-label" htmlFor="collectionPageDesignBrief">Kategori Sayfası Tasarım Briefi:</label>
        <span className="design-details-info">Site kategori sayfası, ürün kartı(ürün resmi, ürün adı, favori iconu, ürün fiyatı, indirim oranı gösterimi vb.) ve filtre için mobil ve desktop için ayrı ayrı olacak şekilde briefinizi yazınız.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <textarea
          className="design-details-textarea"
          id="collectionPageDesignBrief"
          name="collectionPageDesignBrief"
          value={values.collectionPageDesignBrief}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="design-details-section">
        <label className="design-details-label">Kategori Sayfası Tasarım Ekran Görüntüleri:</label>
        <span className="design-details-info">Kategori, filtre ve ürün kartı tasarımları için beğendiğiniz sitelerdeki ekran görüntülerini ekleyiniz.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <div className="design-details-dropzone">
          <Dropzone 
            fieldName="collectionPageScreenshots" 
            customerId={customerId} 
            setFieldValue={setFieldValue}
            values={values}
          />
        </div>
      </div>

      <div className="design-details-section">
        <label className="design-details-label" htmlFor="productPageDesignBrief">Ürün Sayfası Tasarımı:</label>
        <span className="design-details-info">Site ürün sayfası (görsel kullanımı, gösterilecek bilgiler, ürün bilgileri konumlaması vb.) için mobil ve desktop için ayrı ayrı olacak şekilde briefinizi yazınız.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <textarea
          className="design-details-textarea"
          id="productPageDesignBrief"
          name="productPageDesignBrief"
          value={values.productPageDesignBrief}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="design-details-section">
        <label className="design-details-label">Ürün Sayfası Tasarım Ekran Görüntüleri:</label>
        <span className="design-details-info">Ürün sayfası ve içerisinde yer alan bilgileri için beğendiğiniz sitelerdeki ekran görüntülerini ekleyiniz.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <div className="design-details-dropzone">
          <Dropzone 
            fieldName="productPageScreenshots" 
            customerId={customerId} 
            setFieldValue={setFieldValue}
            values={values}
          />
        </div>
      </div>

      <div className="design-details-section">
        <label className="design-details-label" htmlFor="otherPagesDesignBrief">Diğer Sayfalar Tasarımı:</label>
        <span className="design-details-info">Sepet sayfası, sıkça sorulan sorular, hakkımızda ve blog gibi diğer sayfalar için mobil ve desktop için ayrı ayrı olacak şekilde briefinizi yazınız.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <textarea
          className="design-details-textarea"
          id="otherPagesDesignBrief"
          name="otherPagesDesignBrief"
          value={values.otherPagesDesignBrief}
          onChange={handleInputChange}
        />
      </div>

      <div className="design-details-section">
        <label className="design-details-label">Diğer Sayfalar Tasarım Ekran Görüntüleri:</label>
        <span className="design-details-info">Sepet sayfası, sıkça sorulan sorular, hakkımızda ve blog gibi diğer sayfalar için beğendiğiniz sitelerdeki ekran görüntülerini ekleyiniz.</span>
        <span className="design-details-info">Bu alanın boş bırakılması durumunda genel tasarım briefine göre ilerlenecektir. Genel tasarım briefinde iletilen brief dikkate alınacak özel bir talep olmadığı varsayılacaktır.</span>
        <div className="design-details-dropzone">
          <Dropzone 
            fieldName="otherPagesScreenshots" 
            customerId={customerId} 
            setFieldValue={setFieldValue}
            values={values}
          />
        </div>
      </div>

    </div>
  );
};

export default DesignDetails;
