import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PersonalInfo = ({ handleInputChange, values, nextStep }) => {
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    
    // Checkbox durumunu güncelle
    handleInputChange({ 
      target: { 
        name: 'isPointOfContact', 
        value: isChecked 
      } 
    });

    if (isChecked) {
      // Checkbox işaretlendiğinde üstteki bilgileri alt alanlara kopyala
      handleInputChange({ target: { name: 'pocName', value: values.name || '' } });
      handleInputChange({ target: { name: 'pocEmail', value: values.email || '' } });
      handleInputChange({ target: { name: 'pocPhone', value: values.phoneNumber || '' } });
    } else {
      // Checkbox işareti kaldırıldığında alt alanları temizle
      handleInputChange({ target: { name: 'pocName', value: '' } });
      handleInputChange({ target: { name: 'pocEmail', value: '' } });
      handleInputChange({ target: { name: 'pocPhone', value: '' } });
    }
  };

  const handlePhoneChange = (value, data) => {
    if (!value) return;
    
    // Ülke kodu ve numarayı birleştir
    const fullNumber = value.startsWith('+') ? value : `+${data.dialCode}${value.slice(data.dialCode.length)}`;
    
    handleInputChange({
      target: {
        name: 'phoneNumber',
        value: fullNumber
      }
    });
  };

  const handlePocPhoneChange = (value, data) => {
    if (!value) return;
    
    // Ülke kodu ve numarayı birleştir
    const fullNumber = value.startsWith('+') ? value : `+${data.dialCode}${value.slice(data.dialCode.length)}`;
    
    handleInputChange({
      target: {
        name: 'pocPhone',
        value: fullNumber
      }
    });
  };

  return (
    <div className="personal-info-container">
      <div className="personal-info-section">
        <label className="personal-info-label" htmlFor="name">Adınız Soyadınız: <span className="formrequiredsign">*</span></label>
        <input
          className="personal-info-input"
          type="text"
          id="name"
          name="name"
          value={values.name || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="personal-info-section">
        <label className="personal-info-label" htmlFor="email">E-posta Adresiniz: <span className="formrequiredsign">*</span></label>
        <input
          className="personal-info-input"
          type="email"
          id="email"
          name="email"
          value={values.email || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="personal-info-section">
        <label className="personal-info-label" htmlFor="phoneNumber">Telefon Numaranız: <span className="formrequiredsign">*</span></label>
        <div className="personal-info-phone">
          <PhoneInput
            country={'tr'}
            value={values.phoneNumber || ''}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'phoneNumber',
              required: true,
              className: 'personal-info-phone-input'
            }}
          />
        </div>
      </div>

      <div className="personal-info-section">
        <div className="personal-info-checkbox-container">
          <label className="personal-info-checkbox-label">
            <input
              className="personal-info-checkbox-input"
              type="checkbox"
              name="isPointOfContact"
              checked={values.isPointOfContact}
              onChange={handleCheckboxChange}
            />
            <span className="personal-info-checkbox-custom"></span>
            <span className="personal-info-checkbox-text">Ben iletişim kişisiyim</span>
          </label>
        </div>
      </div>

      <div className="personal-info-section">
        <label className="personal-info-label" htmlFor="pocName">İletişim Kişisi Adı Soyadı: <span className="formrequiredsign">*</span></label>
        <input
          className="personal-info-input"
          type="text"
          id="pocName"
          name="pocName"
          value={values.pocName || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="personal-info-section">
        <label className="personal-info-label" htmlFor="pocEmail">İletişim Kişisi E-posta: <span className="formrequiredsign">*</span></label>
        <input
          className="personal-info-input"
          type="email"
          id="pocEmail"
          name="pocEmail"
          value={values.pocEmail || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="personal-info-section">
        <label className="personal-info-label" htmlFor="pocPhone">İletişim Kişisi Telefon: <span className="formrequiredsign">*</span></label>
        <div className="personal-info-phone">
          <PhoneInput
            country={'tr'}
            value={values.pocPhone || ''}
            onChange={handlePocPhoneChange}
            inputProps={{
              name: 'pocPhone',
              required: true,
              className: 'personal-info-phone-input'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;